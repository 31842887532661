import React from "react"
import styles from "./director.module.css"
// import axios from "axios"
import Post from "./post"
import Iphone from "./iphone"
import ReactGA from "react-ga"

class Director extends React.Component {
  state = {
    userDevice: "",
    error: "",
    post: {},
    loaded: false,
  }

  async componentDidMount() {
    try {
      const res = await this.getSinglePost(this.props.match.params.id)
      this.setState({
        post: res,
        userDevice: window.navigator.userAgent,
        loaded: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  trackEvent = () => {
    const category = "Test Category"
    const label = "Test Label"
    const action = "Test Action"
    // ReactGA.event({ category: category, action: action })
    console.log(ReactGA.event({ category, action, label }))
  }

  getSinglePost = (postId) => {
    const contentful = require("contentful")
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      environment: "master",
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      removeUnresolved: true,
    })
    return client.getEntry(postId).catch(console.error)
  }

  async share(state) {
    const shareData = {
      title: "ShortSport",
      text: state.post.fields.title,
      url: `https://www.shrtsprt.com/${state.post.sys.id}`,
    }
    try {
      await navigator.share(shareData)
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const iphone = this.state.userDevice.includes("iPhone")

    return (
      <div className={styles.background}>
        {iphone ? (
          <Iphone />
        ) : (
          <div>
            {this.state.loaded ? (
              <Post post={this.state.post} />
            ) : (
              <div className="spinner">
                <div className="ldsringwhite">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        )}
        <button onClick={this.trackEvent}>Call Us</button>
      </div>
    )
  }
}
export default Director
