import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Error from "./components/error"
import Post from "./components/post"
import Home from "./components/home"
import Director from "./components/director"
import ReactGA from "react-ga"

class App extends React.Component {
  state = {}

  componentDidMount = () => {
    this.setGA()
  }

  setGA = () => {
    ReactGA.initialize("G-WE8DXYJFYL")
    ReactGA.pageview("Init page view")
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/post/:id" component={Post} />
          <Route path="/:id" component={Director} />
          <Route path="/" component={Home} />
          <Route path="/*" component={Error} />
        </Switch>
      </BrowserRouter>
    )
  }
}
export default App
