import React from "react"
import Golf from "./golf"
import Tennis from "./tennis"
import Versus from "./versus"
import Olympics from "./olympics"
import Multiple from "./multiple"
import styles from "./post.module.css"
import Logo from "./logo"
import "./base.css"

class Post extends React.Component {
  state = {
    text: true,
    video: false,
    comments: false,
  }

  changeBlock = (event) => {
    const blank = {
      text: false,
      video: false,
      comments: false,
      likes: false,
    }
    const newBlock = { ...blank, [event.target.value]: true }
    this.setState({ ...newBlock })
  }

  render() {
    const post = this.props.post.fields
    // console.log(post)

    const versus =
      post.sport.fields.type === "Rugby" ||
      post.sport.fields.type === "Football" ||
      post.sport.fields.type === "Cricket"
    const race = post.sport.fields.type === "Motorsport"
    const golf = post.sport.fields.type === "Golf"
    const tennis = post.sport.fields.type === "Tennis"
    const olympics = post.sport.fields.type === "Olympics"

    let videoId = ""
    if (post.video) {
      if (post.video.includes("watch?v=")) {
        videoId = post.video.split("watch?v=")[1]
      } else {
        videoId = post.video.split(".be/")[1]
      }
    }

    // console.log(race)

    return (
      <div className={styles.post}>
        <Logo />
        <div className={styles.preview}>
          <div className={`${post.sport.fields.type}header`}></div>
          <div className={styles.topcontent}>
            <div className={styles.title}>
              <div className={styles.competition}>
                <div className={styles.titlelogocontainer}>
                  {post.competition.fields.logo ? (
                    <img
                      src={`https://${post.competition.fields.logo.fields.file.url}`}
                      className={styles.titlelogo}
                      alt={post.competition.fields.logo.title}
                    />
                  ) : null}
                </div>
                <div className={styles.competitiondetail}>
                  <div
                    to="/app/competition/"
                    className={styles.competitionname}
                    state={post.competition}
                  >
                    {post.competition.fields.name}
                  </div>
                  {post.event ? (
                    <div className={styles.event}>{post.event}</div>
                  ) : null}
                </div>
              </div>
              <div
                to="/app/sport/"
                className={`${post.sport.fields.type}`}
                state={post.sport}
              >
                {post.sport.fields.type}
              </div>
            </div>

            <div className={styles.matchdata}>
              {versus ? <Versus post={post} /> : null}
              {race ? <Multiple post={post} /> : null}
              {golf ? <Golf post={post} /> : null}
              {tennis ? <Tennis post={post} /> : null}
              {olympics ? <Olympics post={post} /> : null}
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.selectors}>
              <div className={styles.selectorbox}>
                <button
                  onClick={this.changeBlock}
                  value="text"
                  className={
                    this.state.text ? styles.selected : styles.selector
                  }
                >
                  Read
                </button>
                <div
                  className={
                    this.state.text ? styles.selectedline : styles.selectorline
                  }
                />
              </div>

              <div className={styles.selectorbox}>
                <button
                  onClick={this.changeBlock}
                  value="video"
                  className={
                    this.state.video ? styles.selected : styles.selector
                  }
                >
                  Watch
                </button>
                <div
                  className={
                    this.state.video ? styles.selectedline : styles.selectorline
                  }
                />
              </div>
            </div>

            <div className={styles.contentblock}>
              {this.state.text ? (
                <div>
                  <div className={styles.block}>
                    <div className={styles.paras}>
                      <div className={styles.para}>{post.para1}</div>
                      <div className={styles.divider}></div>
                      <div className={styles.para}>{post.para2}</div>
                      <div className={styles.divider}></div>
                      <div className={styles.para}>{post.para3}</div>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.video ? (
                videoId ? (
                  <div className={styles.block}>
                    <div className={styles.videocontainer}>
                      <iframe
                        className={styles.video}
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={videoId}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.block}>
                    <div className={styles.videocontainer}>
                      We know it's sad, but there's no video for this post
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Post
