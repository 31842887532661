import React from "react"
import styles from "./multiple.module.css"

class Multiple extends React.Component {
  state = {}

  render() {
    const post = this.props.post
    return (
      <div className={styles.matchdata}>
        <div className={styles.driverblock}>
          <div className={styles.positionblock}>
            <h2>1</h2>st
          </div>
          <div to="/app/competitor/" state={post.driver1}>
            <div className={styles.competitorname}>
              {post.driver1.fields.name.split(" ")[0]}
            </div>
            <div className={styles.competitorname}>
              {post.driver1.fields.name.split(" ")[1]}
            </div>
          </div>
        </div>
        <div className={styles.driverblock}>
          <div className={styles.positionblock}>
            <h2>2</h2>nd
          </div>
          <div to="/app/competitor/" state={post.driver2}>
            <div className={styles.competitorname}>
              {post.driver2.fields.name.split(" ")[0]}
            </div>
            <div className={styles.competitorname}>
              {post.driver2.fields.name.split(" ")[1]}
            </div>
          </div>
        </div>
        <div className={styles.driverblock}>
          <div className={styles.positionblock}>
            <h2>3</h2>rd
          </div>
          <div to="/app/competitor/" state={post.driver3}>
            <div className={styles.competitorname}>
              {post.driver3.fields.name.split(" ")[0]}
            </div>
            <div className={styles.competitorname}>
              {post.driver3.fields.name.split(" ")[1]}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Multiple
