import React from "react"

class Error extends React.Component {
  state = {}

  render() {
    return (
      <div>
        <div>Oooops error page</div>
      </div>
    )
  }
}
export default Error
