import React from "react"

class Home extends React.Component {
  state = {}

  render() {
    return (
      <div>
        <div>Home page</div>
      </div>
    )
  }
}
export default Home
