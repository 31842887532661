import React from "react"
import styles from "./tennis.module.css"

class Tennis extends React.Component {
  state = {}

  render() {
    const post = this.props.post

    return (
      <div className={styles.matchdata}>
        <div className={styles.team}>
          {post.team1.fields.logo ? (
            <div className={styles.logocontainer}>
              <img
                src={`https://${post.team1.fields.logo.fields.file.url}`}
                className={styles.logo}
                alt={post.team1.fields.logo.fields.title}
              />
            </div>
          ) : null}
          <div className={styles.score}>{post.team1Score}</div>
          <div
            to="/app/competitor/"
            className={styles.teamname}
            state={post.team1}
          >
            {post.team1.fields.name}
          </div>
          {post.team1Partner ? (
            <div
              to="/app/competitor/"
              className={styles.teamname}
              state={post.team1Partner}
            >
              {post.team1Partner.fields.name}
            </div>
          ) : null}
        </div>

        <div className={styles.team}>
          {post.team2.fields.logo ? (
            <div className={styles.logocontainer}>
              <img
                src={`https://${post.team2.fields.logo.fields.file.url}`}
                className={styles.logo}
                alt={post.team2.fields.logo.fields.title}
              />
            </div>
          ) : null}
          <div className={styles.score}>{post.team2Score}</div>
          <div
            to="/app/competitor/"
            className={styles.teamname}
            state={post.team2}
          >
            {post.team2.fields.name}
          </div>
          {post.team2Partner ? (
            <div
              to="/app/competitor/"
              className={styles.teamname}
              state={post.team2Partner}
            >
              {post.team2Partner.fields.name}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
export default Tennis
