import React from "react"
import styles from "./versus.module.css"

class Versus extends React.Component {
  state = {}

  render() {
    const post = this.props.post
    return (
      <div className={styles.matchdata}>
        <div className={styles.team}>
          <div
            to="/app/competitor/"
            className={styles.teamname}
            state={post.team1}
          >
            {post.team1.fields.logo ? (
              <div className={styles.logocontainer}>
                <img
                  src={`https://${post.team1.fields.logo.fields.file.url}`}
                  className={styles.logo}
                  alt={post.team1.fields.logo.fields.title}
                />
              </div>
            ) : null}
            <div className={styles.score}>{post.team1Score}</div>
            {post.team1.fields.name}
          </div>
        </div>

        <div className={styles.versuscontainer}>
          <div className={styles.versus}>v</div>
        </div>

        <div className={styles.team}>
          <div
            to="/app/competitor/"
            className={styles.teamname}
            state={post.team2}
          >
            {post.team2.fields.logo ? (
              <div className={styles.logocontainer}>
                <img
                  src={`https://${post.team2.fields.logo.fields.file.url}`}
                  className={styles.logo}
                  alt={post.team2.fields.logo.fields.title}
                />
              </div>
            ) : null}
            <div className={styles.score}>{post.team2Score}</div>
            {post.team2.fields.name}
          </div>
        </div>
      </div>
    )
  }
}
export default Versus
