import React from "react"
import Logo from "./logo"
import styles from "./iphone.module.css"
import appStore from "../assets/download-on-the-app-store.svg"
// import teams from "../assets/icons/users-pngrepo-com.png"

class Iphone extends React.Component {
  state = {}

  async openApp() {
    try {
      window.location = "shortsport.org://shortsport.org/app/feed"
    } catch (e) {
      console.log("goodbye")
    }
  }

  async share() {
    console.log("hello")
  }

  render() {
    return (
      <div className={styles.container}>
        <Logo />
        <div className={styles.buttons}>
          Already a ShortSporter?
          <button className={styles.button} onClick={this.openApp}>
            Open ShortSport
          </button>
          No idea what we're talking about?
          <a
            className={styles.appStore}
            href="https://apps.apple.com/gb/app/shortsport/id1442511762"
          >
            <img src={appStore} alt="Download on the appStore" />
          </a>
        </div>
      </div>
    )
  }
}
export default Iphone
