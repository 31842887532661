import React from "react"
import Versus from "./versus"
import styles from "./multiple.module.css"

class Olympics extends React.Component {
  state = {}

  render() {
    const post = this.props.post

    return (
      <div>
        {post.team3 ? (
          <div className={styles.matchdata}>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <div className={styles.score}>{post.team1Score}</div>
              </div>
              <div to="/app/competitor/" state={post.team1}>
                <div className={styles.link}>
                  <div className={styles.competitorname}>
                    {post.team1.fields.name}
                  </div>
                  {post.team1.fields.country ? (
                    <div className={styles.logocontainer}>
                      <img
                        src={`https://${post.team1.fields.country.fields.logo.fields.file.url}`}
                        className={styles.logo}
                        alt={
                          post.team1.fields.country.fields.logo.fields.file
                            .title
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <div className={styles.score}>{post.team2Score}</div>
              </div>
              <div to="/app/competitor/" state={post.team2}>
                <div className={styles.link}>
                  <div className={styles.competitorname}>
                    {post.team2.fields.name}
                  </div>
                  {post.team2.fields.country ? (
                    <div className={styles.logocontainer}>
                      <img
                        src={`https://${post.team2.fields.country.fields.logo.fields.file.url}`}
                        className={styles.logo}
                        alt={
                          post.team2.fields.country.fields.logo.fields.file
                            .title
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <div className={styles.score}>{post.team3Score}</div>
              </div>
              <div to="/app/competitor/" state={post.team3}>
                <div className={styles.link}>
                  <div className={styles.competitorname}>
                    {post.team3.fields.name}
                  </div>
                  {post.team3.fields.country ? (
                    <div className={styles.logocontainer}>
                      <img
                        src={`https://${post.team3.fields.country.fields.logo.fields.file.url}`}
                        className={styles.logo}
                        alt={
                          post.team3.fields.country.fields.logo.fields.file
                            .title
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Versus post={post} />
        )}
      </div>
    )
  }
}
export default Olympics
