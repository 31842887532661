import React from "react"
import styles from "./multiple.module.css"

class Golf extends React.Component {
  state = {}

  render() {
    const post = this.props.post
    return (
      <div>
        {post.competition.fields.format === "Single" ? (
          <div className={styles.matchdata}>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <h2>{post.team1Score}</h2>
              </div>
              <div to="/app/competitor/" state={post.team1}>
                <div className={styles.competitorname}>
                  {post.team1.fields.name.split(" ")[0]}
                </div>
                <div className={styles.competitorname}>
                  {post.team1.fields.name.split(" ")[1]}
                </div>
              </div>
            </div>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <h2>{post.team2Score}</h2>
              </div>
              <div to="/app/competitor/" state={post.team2}>
                <div className={styles.competitorname}>
                  {post.team2.fields.name.split(" ")[0]}
                </div>
                <div className={styles.competitorname}>
                  {post.team2.fields.name.split(" ")[1]}
                </div>
              </div>
            </div>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <h2>{post.team3Score}</h2>
              </div>
              <div to="/app/competitor/" state={post.team3}>
                <div className={styles.competitorname}>
                  {post.team3.fields.name.split(" ")[0]}
                </div>
                <div className={styles.competitorname}>
                  {post.team3.fields.name.split(" ")[1]}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.matchdata}>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <h2>{post.team1Score}</h2>
              </div>
              <div to="/app/competitor/" state={post.team1}>
                <div className={styles.competitorname}>
                  {post.team1.fields.name}
                </div>
              </div>
            </div>
            <div className={styles.driverblock}>
              <div className={styles.positionblock}>
                <h2>{post.team2Score}</h2>
              </div>
              <div to="/app/competitor/" state={post.team2}>
                <div className={styles.competitorname}>
                  {post.team2.fields.name}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default Golf
